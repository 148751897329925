import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useLoading } from "contexts/loadingContext";

export type OnSubmitFunction = {
  execute: (data: FieldValues) => void;
  when: "success" | "fail" | "both";
};

type EmailSubscribeFormProps = {
  submitText: string;
  subscriptionList:
    | "aulas-fertilizantes"
    | "aula-gratuita"
    | "consultoria-gratuita"
    | "mini-treinamento";
  onSubmitFunctions: OnSubmitFunction[];
};

type EmailSubscribeBody = {
  name: string;
  email: string;
  whatsapp: number;
  subscription_list: EmailSubscribeFormProps["subscriptionList"];
  utm_source: string;
  utm_medium: string;
  utm_content: string;
  utm_campaign: string;
  utm_term: string;
};

const EmailSubscribeForm = ({
  submitText,
  subscriptionList,
  onSubmitFunctions,
}: EmailSubscribeFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    criteriaMode: "all",
  });

  const { setIsLoading } = useLoading();

  
  const onSubmit = async (data: FieldValues) => {
    const urlParams = new URLSearchParams(window.location.search);
    const getParam = (param: string) => urlParams.get(param) ?? "direct";
  
    const body: EmailSubscribeBody = {
      name: data.nome,
      email: data.email,
      whatsapp: Number(data.whatsapp.replace(/\D/g, '')),
      subscription_list: subscriptionList,
      utm_source: getParam("utm_source"),
      utm_medium: getParam("utm_medium"),
      utm_content: getParam("utm_content"),
      utm_campaign: getParam("utm_campaign"),
      utm_term: getParam("utm_term"),
    };
    console.log(body)
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://9mxyf4e4ce.execute-api.sa-east-1.amazonaws.com/prod/email_subscribe",
        { method: "post", body: JSON.stringify(body) }
      );
      if (response.ok) {
        onSubmitFunctions.forEach((func) => {
          if (func.when !== "fail") func.execute(data);
        });
      } else {
        onSubmitFunctions.forEach((func) => {
          if (func.when !== "success") func.execute(data);
        });
      }
    } catch (e) {
      onSubmitFunctions.forEach((func) => {
        if (func.when !== "fail") func.execute(data);
      });
    }
    setIsLoading(false);
  };
  const regexEmail =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    <form className="text-left text-black" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col mb-4">
        <input
          className="border rounded-sm bg-gray-100 placeholder:text-gray-400 placeholder:font-bold border-gray-600 p-2 focus:outline-black"
          placeholder="INSIRA SEU NOME"
          {...register("nome", {
            required: { value: true, message: "Campo obrigatório" },
            pattern: {
              value: /\w+/,
              message: "Preencha seu nome corretamente",
            },
            maxLength: { value: 60, message: "Nome muito grande" },
          })}
        />
        <ErrorMessage
          errors={errors}
          name="nome"
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <span key={type} className="text-strongRed">
                {message}
              </span>
            ))
          }
        />
      </div>

      <div className="flex flex-col mb-4">
        <input
          className="border rounded-sm bg-gray-100 placeholder:text-gray-400 placeholder:font-bold border-gray-600 p-2 focus:outline-black"
          placeholder="INSIRA SEU E-MAIL"
          {...register("email", {
            required: { value: true, message: "Campo obrigatório" },
            pattern: { value: regexEmail, message: "Insira um e-mail válido" },
          })}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <span key={type} className="text-strongRed">
                {message}
              </span>
            ))
          }
        />
      </div>

      <div className="flex flex-col mb-4">
        <input
          className="border rounded-sm bg-gray-100 placeholder:text-gray-400 placeholder:font-bold border-gray-600 p-2 focus:outline-black"
          placeholder="INSIRA SEU WHATSAPP"
          {...register("whatsapp", {
            required: { value: true, message: "Campo obrigatório" },
            pattern: {
              value: /^\(?[0-9]{2}\)?\s?[0-9]{5}[-\s]?[0-9]{4}$/,
              message: "Insira um número válido",
            },
          })}
        />
        <ErrorMessage
          errors={errors}
          name="whatsapp"
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <span key={type} className="text-strongRed">
                {message}
              </span>
            ))
          }
        />
      </div>
      <input
        className="bg-green focus:bg-semidarkGreen rounded-2xl cursor-pointer w-full my-3 pt-2 pb-3 text-white font-bold text-2xl"
        type="submit"
        value={submitText}
      />
    </form>
  );
};

export default EmailSubscribeForm;
